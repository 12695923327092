import { requiredField } from '../../../validation/validation';
import { CRITERE, CRITERE_OFFRE, TYPE_BIEN } from '../../type';
export const Equipements = [
	{
		//Chauffage
		group: 'info_personnelle',
		className: 'w-full  !justify-start mt-4 sm:pl-4 underline',
		title: 'Chauffage :',
		visible: [
			{ field: 'type_offert_id', value: ['1', '2'] },
			{ field: 'type_offer_suite_id', value: ['10'], condition: 'INEQUAL' },
			{
				field: CRITERE.formName,
				value: [...CRITERE_OFFRE.PARKING_CAVE_GARAGE],
				condition: 'INEQUAL',
			},
			{ field: 'type_estate_id', value: ['1', '2', TYPE_BIEN.TERRAIN] },
		],
	},
	{
		//Format
		group: 'info_personnelle',
		validation: {
			validate: { requiredField },
		},
		className: 'w-full sm:w-4/12 ',
		type: 'select',
		title: 'Format :',
		name: 'format',
		tagName: 'equipment.format',
		choices: [
			{
				title: 'Non précisé',
				value: 'Non précisé',
			},
			{
				title: 'Central',
				value: 'Central',
			},
			{
				title: 'Collectif',
				value: 'Collectif',
			},
			{
				title: 'Individuel',
				value: 'Individuel',
			},
			{
				title: 'Urbain',
				value: 'Urbain',
			},
		],
		visible: [
			{ field: 'type_offert_id', value: ['1', '2'] },
			{ field: 'type_offer_suite_id', value: ['10'], condition: 'INEQUAL' },
			{
				field: CRITERE.formName,
				value: [...CRITERE_OFFRE.PARKING_CAVE_GARAGE],
				condition: 'INEQUAL',
			},
			{ field: 'type_estate_id', value: ['1', '2', TYPE_BIEN.TERRAIN] },
		],
	},
	{
		//Types
		group: 'info_personnelle',
		validation: {
			validate: { requiredField },
		},
		className: 'w-full sm:w-4/12',
		type: 'select',
		title: 'Types :',
		name: 'type',
		tagName: 'equipment.type',
		choices: [
			{
				title: 'Non précisé',
				value: 'Non précisé',
			},
			{
				title: 'Air',
				value: 'Air',
			},
			{
				title: 'Chaudière',
				value: 'Chaudière',
			},
			{
				title: 'Cheminée',
				value: 'Cheminée',
			},
			{
				title: 'Convecteur',
				value: 'Convecteur',
			},
			{
				title: 'Poêle',
				value: 'Poêle',
			},
			{
				title: 'Urbain',
				value: 'Urbain',
			},
			{
				title: 'Autre',
				value: 'Autre',
			},
		],
		visible: [
			{ field: 'type_offert_id', value: ['1', '2'] },
			{ field: 'type_offer_suite_id', value: ['10'], condition: 'INEQUAL' },
			{
				field: CRITERE.formName,
				value: [...CRITERE_OFFRE.PARKING_CAVE_GARAGE],
				condition: 'INEQUAL',
			},
			{ field: 'type_estate_id', value: ['1', '2', TYPE_BIEN.TERRAIN] },
		],
	},
	{
		//Energie
		group: 'info_personnelle',
		validation: {
			validate: { requiredField },
		},
		className: 'w-full sm:w-4/12',
		type: 'select',
		title: 'Energie:',
		name: 'energie',
		tagName: 'equipment.energie',
		choices: [
			{
				title: 'Non précisé',
				value: 'Non précisé',
			},
			{
				title: 'Aerothérmie',
				value: 'Aerothérmie',
			},
			{
				title: 'Bois',
				value: 'Bois',
			},
			{
				title: 'Charbon',
				value: 'Charbon',
			},
			{
				title: 'Climatisation',
				value: 'Climatisation',
			},
			{
				title: 'Electrique',
				value: 'Electrique',
			},
			{
				title: 'Fioul',
				value: 'Fioul',
			},
			{
				title: 'Gaz',
				value: 'Gaz',
			},
			{
				title: 'gaz de ville',
				value: 'gaz de ville',
			},
			{
				title: 'Géothermie',
				value: 'Géothermie',
			},
			{
				title: 'Granules',
				value: 'Granules',
			},
			{
				title: 'Mixte',
				value: 'Mixte',
			},
			{
				title: 'pompe à chaleur',
				value: 'pompe à chaleur',
			},
			{
				title: 'Solaire',
				value: 'Solaire',
			},
			{
				title: 'Sans',
				value: 'Sans',
			},
		],
		visible: [
			{ field: 'type_offert_id', value: ['1', '2'] },
			{ field: 'type_offer_suite_id', value: ['10'], condition: 'INEQUAL' },
			{
				field: CRITERE.formName,
				value: [...CRITERE_OFFRE.PARKING_CAVE_GARAGE],
				condition: 'INEQUAL',
			},
			{ field: 'type_estate_id', value: ['1', '2', TYPE_BIEN.TERRAIN] },
		],
	},
	{
		//Ascensceur
		type: 'radio',
		validation: {
			validate: { requiredField },
		},
		name: 'ascensceur',
		className: 'w-full sm:w-1/2',
		title: 'Ascensceur :',
		tagName: 'equipment.ascensceur',
		choices: [
			{
				title: 'Non précisé',
				value: '1',
			},
			{
				title: 'Non',
				value: '2',
			},
			{
				title: 'Oui',
				value: '3',
			},
		],
		visible: [
			{ condition: 'EQUAL', field: 'type_offert_id', value: ['1', '2'] },
			{
				condition: 'EQUAL',
				field: 'type_offer_suite_id',
				value: ['1', '2', '3'],
			},
			{ condition: 'EQUAL', field: 'type_estate_id', value: ['1', '2', '4'] },
			{
				field: CRITERE.formName,
				value: [...CRITERE_OFFRE.PARKING_CAVE_GARAGE],
				condition: 'INEQUAL',
			},
		],
	},
	{
		//Accés hindicapès
		type: 'radio',
		name: 'acces_handicapes',
		validation: {
			validate: { requiredField },
		},
		className: 'w-full sm:w-1/2 ',
		title: 'Accès handicapés :',
		tagName: 'equipment.acces_handicapes',
		choices: [
			{
				title: 'Non précisé',
				value: '1',
			},
			{
				title: 'Non',
				value: '2',
			},
			{
				title: 'Oui',
				value: '3',
			},
		],
		visible: [
			{ field: 'type_offert_id', value: ['1', '2'] },
			{ field: 'type_offer_suite_id', value: ['10'], condition: 'INEQUAL' },
			{
				field: CRITERE.formName,
				value: [...CRITERE_OFFRE.PARKING_CAVE_GARAGE],
				condition: 'INEQUAL',
			},
			{ field: 'type_estate_id', value: ['1', '2', TYPE_BIEN.TERRAIN] },
		],
	},
	{
		//Climatisation
		type: 'radio',
		validation: {
			validate: { requiredField },
		},
		name: 'climatisation',
		className: 'w-full sm:w-1/2 ',
		title: 'Climatisation :',
		tagName: 'equipment.climatisation',
		choices: [
			{
				title: 'Non précisé',
				value: '1',
			},
			{
				title: 'Non',
				value: '2',
			},
			{
				title: 'Oui',
				value: '3',
			},
		],
		visible: [
			{ condition: 'EQUAL', field: 'type_offert_id', value: ['1', '2'] },
			{
				condition: 'EQUAL',
				field: 'type_offer_suite_id',
				value: ['1', '2', '3'],
			},
			{ condition: 'EQUAL', field: 'type_estate_id', value: ['1', '2', '4'] },
			{
				field: CRITERE.formName,
				value: [...CRITERE_OFFRE.PARKING_CAVE_GARAGE],
				condition: 'INEQUAL',
			},
		],
	},
	{
		//Cheminée
		type: 'radio',
		name: 'chemine',
		validation: {
			validate: { requiredField },
		},
		className: 'w-full sm:w-1/2 ',
		tagName: 'equipment.chemine',
		title: 'Cheminée :',
		choices: [
			{
				title: 'Non précisé',
				value: '1',
			},
			{
				title: 'Non',
				value: '2',
			},
			{
				title: 'Oui',
				value: '3',
			},
		],
		visible: [
			{ condition: 'EQUAL', field: 'type_offert_id', value: ['1', '2'] },
			{
				condition: 'EQUAL',
				field: 'type_offer_suite_id',
				value: ['1', '2', '3'],
			},
			{
				field: CRITERE.formName,
				value: [...CRITERE_OFFRE.PARKING_CAVE_GARAGE],
				condition: 'INEQUAL',
			},
			{ condition: 'EQUAL', field: 'type_estate_id', value: ['1', '2', '4'] },
		],
	},
	{
		//Local à vélos
		type: 'radio',
		name: 'byciclette',
		className: 'w-full sm:w-1/2 ',
		validation: {
			validate: { requiredField },
		},
		tagName: 'equipment.byciclette',
		title: 'Local à vélos :',
		choices: [
			{
				title: 'Non précisé',
				value: '1',
			},
			{
				title: 'Non',
				value: '2',
			},
			{
				title: 'Oui',
				value: '3',
			},
		],
		visible: [
			{ field: 'type_offert_id', value: ['1', '2'] },
			{ field: 'type_offer_suite_id', value: ['10'], condition: 'INEQUAL' },
			{
				field: CRITERE.formName,
				value: [...CRITERE_OFFRE.PARKING_CAVE_GARAGE],
				condition: 'INEQUAL',
			},
			{ field: 'type_estate_id', value: ['1', '2', TYPE_BIEN.TERRAIN] },
		],
	},
	{
		//Eau
		group: 'info_personnelle',
		className: 'w-full  !justify-start mt-4 sm:pl-4 underline',
		title: 'Eau :',
		visible: [
			{ field: 'type_offert_id', value: ['1', '2'] },
			{ field: 'type_offer_suite_id', value: ['10'], condition: 'INEQUAL' },
			{
				field: CRITERE.formName,
				value: [...CRITERE_OFFRE.PARKING_CAVE_GARAGE],
				condition: 'INEQUAL',
			},
			{ field: 'type_estate_id', value: ['1', '2', TYPE_BIEN.TERRAIN] },
		],
	},
	{
		//Alimentation
		group: 'info_personnelle',
		validation: {
			validate: { requiredField },
		},
		className: 'w-full sm:w-3/4 ',
		type: 'select',
		title: 'Alimentation :',
		name: 'water_alimentation',
		tagName: 'equipment.water_alimentation',
		choices: [
			{
				title: 'Non précisé',
				value: 'Non précisé',
			},
			{
				title: 'Sans',
				value: 'Sans',
			},
			{
				title: 'Individuel',
				value: 'Individuel',
			},
			{
				title: 'Puits',
				value: 'Puits',
			},
		],
		visible: [
			{ field: 'type_offert_id', value: ['1', '2'] },
			{ field: 'type_offer_suite_id', value: ['10'], condition: 'INEQUAL' },
			{
				field: CRITERE.formName,
				value: [...CRITERE_OFFRE.PARKING_CAVE_GARAGE],
				condition: 'INEQUAL',
			},
			{ field: 'type_estate_id', value: ['1', '2', TYPE_BIEN.TERRAIN] },
		],
	},
	{
		//Assainissement
		group: 'info_personnelle',
		className: 'w-full sm:w-3/4',
		type: 'select',
		validation: {
			validate: { requiredField },
		},
		title: 'Assainissement :',
		name: 'water_clean',
		tagName: 'equipment.water_clean',
		choices: [
			{
				title: 'Non précisé',
				value: 'Non précisé',
			},
			{
				title: 'Sans',
				value: 'Sans',
			},
			{
				title: "Tout à l'égout",
				value: "Tout à l'égout",
			},
			{
				title: 'Fosse septique',
				value: 'Fosse septique',
			},
			{
				title: 'Fosse toutes eaux',
				value: 'Fosse toutes eaux',
			},
		],
		visible: [
			{ field: 'type_offert_id', value: ['1', '2'] },
			{ field: 'type_offer_suite_id', value: ['10'], condition: 'INEQUAL' },
			{
				field: CRITERE.formName,
				value: [...CRITERE_OFFRE.PARKING_CAVE_GARAGE],
				condition: 'INEQUAL',
			},
			{ field: 'type_estate_id', value: ['1', '2', TYPE_BIEN.TERRAIN] },
		],
	},
	{
		//volets éléctrique
		type: 'radio',
		validation: {
			validate: { requiredField },
		},
		name: 'electric_volet',
		className: 'w-full sm:w-1/2 ',
		title: 'Volets éléctrique :',
		tagName: 'equipment.electric',
		choices: [
			{
				title: 'Non précisé',
				value: '1',
			},
			{
				title: 'Non',
				value: '2',
			},
			{
				title: 'Oui',
				value: '3',
			},
		],
		visible: [
			{ condition: 'EQUAL', field: 'type_offert_id', value: ['1', '2'] },
			{
				condition: 'EQUAL',
				field: 'type_offer_suite_id',
				value: ['1', '2', '3'],
			},
			{ condition: 'EQUAL', field: 'type_estate_id', value: ['1', '2', '4'] },
			{
				field: CRITERE.formName,
				value: [...CRITERE_OFFRE.PARKING_CAVE_GARAGE],
				condition: 'INEQUAL',
			},
		],
	},
	{
		//Portail électrique
		type: 'radio',
		name: 'portal_electric',
		validation: {
			validate: { requiredField },
		},
		className: 'w-full sm:w-1/2 ',
		title: 'Portail électrique :',
		tagName: 'equipment.portal_electric',
		choices: [
			{
				title: 'Non précisé',
				value: '1',
			},
			{
				title: 'Non',
				value: '2',
			},
			{
				title: 'Oui',
				value: '3',
			},
		],
		visible: [
			{ field: 'type_offert_id', value: ['1', '2'] },
			{ field: 'type_offer_suite_id', value: ['10'], condition: 'INEQUAL' },
			{
				field: CRITERE.formName,
				value: [...CRITERE_OFFRE.PARKING_CAVE_GARAGE],
				condition: 'INEQUAL',
			},
			{ field: 'type_estate_id', value: ['1', '2', TYPE_BIEN.TERRAIN] },
		],
	},
	{
		//gardien
		type: 'radio',
		name: 'garden',
		className: 'w-full sm:w-1/2 ',
		validation: {
			validate: { requiredField },
		},
		title: 'Gardien :',
		tagName: 'equipment.garden',
		choices: [
			{
				title: 'Non précisé',
				value: '1',
			},
			{
				title: 'Non',
				value: '2',
			},
			{
				title: 'Oui',
				value: '3',
			},
		],
		visible: [
			{ field: 'type_offert_id', value: ['1', '2'] },
			{ field: 'type_offer_suite_id', value: ['10'], condition: 'INEQUAL' },
			{
				field: CRITERE.formName,
				value: [...CRITERE_OFFRE.PARKING_CAVE_GARAGE],
				condition: 'INEQUAL',
			},
			{ field: 'type_estate_id', value: ['1', '2', TYPE_BIEN.TERRAIN] },
		],
	},
	{
		//Vitrage
		type: 'select',
		name: 'vitrage',
		validation: {
			validate: { requiredField },
		},
		className: 'w-full sm:w-1/2 ',
		tagName: 'equipment.vitrage',
		title: 'Vitrage :',
		choices: [
			{
				title: 'Simple',
				value: 'Simple',
			},
			{
				title: 'Double',
				value: 'Double',
			},
			{
				title: 'Triple',
				value: 'Triple',
			},
		],
		visible: [
			{ condition: 'EQUAL', field: 'type_offert_id', value: ['1', '2'] },
			{
				condition: 'EQUAL',
				field: 'type_offer_suite_id',
				value: ['1', '2', '3'],
			},
			{ condition: 'EQUAL', field: 'type_estate_id', value: ['1', '2', '4'] },
			{
				field: CRITERE.formName,
				value: [...CRITERE_OFFRE.PARKING_CAVE_GARAGE],
				condition: 'INEQUAL',
			},
		],
	},
	{
		//internet
		type: 'select',
		name: 'internet',
		validation: {
			validate: { requiredField },
		},
		className: 'w-full sm:w-1/2 ',
		title: 'Internet :',
		tagName: 'equipment.internet',
		choices: [
			{
				title: 'Non raccordé',
				value: 'Non raccordé',
			},
			{
				title: 'raccordé',
				value: 'raccordé',
			},
			{
				title: 'ADSL',
				value: 'ADSL',
			},
			{
				title: 'Fibre',
				value: 'Fibre',
			},
		],
		visible: [
			{ condition: 'EQUAL', field: 'type_offert_id', value: ['1', '2'] },
			{
				condition: 'EQUAL',
				field: 'type_offer_suite_id',
				value: ['1', '2', '3'],
			},
			{ condition: 'EQUAL', field: 'type_estate_id', value: ['1', '2', '4'] },
			{
				field: CRITERE.formName,
				value: [...CRITERE_OFFRE.PARKING_CAVE_GARAGE],
				condition: 'INEQUAL',
			},
		],
	},
	{
		//Sécurité
		group: 'info_personnelle',
		validation: {
			validate: { requiredField },
		},
		className: 'w-full  !justify-start mt-4 sm:pl-4 underline',
		title: 'Sécurité :',
		visible: [
			{ condition: 'EQUAL', field: 'type_offert_id', value: ['1', '2'] },
			{
				condition: 'EQUAL',
				field: 'type_offer_suite_id',
				value: ['1', '2', '3'],
			},
			{ condition: 'EQUAL', field: 'type_estate_id', value: ['1', '2', '4'] },
			{
				field: CRITERE.formName,
				value: [...CRITERE_OFFRE.PARKING_CAVE_GARAGE],
				condition: 'INEQUAL',
			},
		],
	},
	{
		//Port blindée
		type: 'radio',
		validation: {
			validate: { requiredField },
		},
		name: 'portals',
		className: 'w-full sm:w-1/2 ',
		title: 'Port blindée :',
		tagName: 'equipment.portals',
		choices: [
			{
				title: 'Non précisé',
				value: '1',
			},
			{
				title: 'Non',
				value: '2',
			},
			{
				title: 'Oui',
				value: '3',
			},
		],
		visible: [
			{ condition: 'EQUAL', field: 'type_offert_id', value: ['1', '2'] },
			{
				condition: 'EQUAL',
				field: 'type_offer_suite_id',
				value: ['1', '2', '3'],
			},
			{
				field: CRITERE.formName,
				value: [...CRITERE_OFFRE.PARKING_CAVE_GARAGE],
				condition: 'INEQUAL',
			},
			{ condition: 'EQUAL', field: 'type_estate_id', value: ['1', '2', '4'] },
		],
	},
	{
		//Interphone
		type: 'radio',
		name: 'interphone',
		validation: {
			validate: { requiredField },
		},
		className: 'w-full sm:w-1/2 ',
		tagName: 'equipment.interphone',
		title: 'Interphone :',
		choices: [
			{
				title: 'Non précisé',
				value: '1',
			},
			{
				title: 'Non',
				value: '2',
			},
			{
				title: 'Oui',
				value: '3',
			},
		],
		visible: [
			{ condition: 'EQUAL', field: 'type_offert_id', value: ['1', '2'] },
			{
				condition: 'EQUAL',
				field: 'type_offer_suite_id',
				value: ['1', '2', '3'],
			},
			{
				field: CRITERE.formName,
				value: [...CRITERE_OFFRE.PARKING_CAVE_GARAGE],
				condition: 'INEQUAL',
			},
			{ condition: 'EQUAL', field: 'type_estate_id', value: ['1', '2', '4'] },
		],
	},
	{
		//Visiophone
		type: 'radio',
		name: 'visiophone',
		validation: {
			validate: { requiredField },
		},
		className: 'w-full sm:w-1/2 ',
		tagName: 'equipment.visiophone',
		title: 'Visiophone :',
		choices: [
			{
				title: 'Non précisé',
				value: '1',
			},
			{
				title: 'Non',
				value: '2',
			},
			{
				title: 'Oui',
				value: '3',
			},
		],
		visible: [
			{ condition: 'EQUAL', field: 'type_offert_id', value: ['1', '2'] },
			{
				condition: 'EQUAL',
				field: 'type_offer_suite_id',
				value: ['1', '2', '3'],
			},
			{
				field: CRITERE.formName,
				value: [...CRITERE_OFFRE.PARKING_CAVE_GARAGE],
				condition: 'INEQUAL',
			},
			{ condition: 'EQUAL', field: 'type_estate_id', value: ['1', '2', '4'] },
		],
	},
	{
		//Alarme
		type: 'radio',
		name: 'alarme',
		validation: {
			validate: { requiredField },
		},
		className: 'w-full sm:w-1/2 ',
		tagName: 'equipment.alarme',
		title: 'Alarme :',
		choices: [
			{
				title: 'Non précisé',
				value: '1',
			},
			{
				title: 'Non',
				value: '2',
			},
			{
				title: 'Oui',
				value: '3',
			},
		],
		visible: [
			{ condition: 'EQUAL', field: 'type_offert_id', value: ['1', '2'] },
			{
				condition: 'EQUAL',
				field: 'type_offer_suite_id',
				value: ['1', '2', '3'],
			},
			{
				field: CRITERE.formName,
				value: [...CRITERE_OFFRE.PARKING_CAVE_GARAGE],
				condition: 'INEQUAL',
			},
			{ condition: 'EQUAL', field: 'type_estate_id', value: ['1', '2', '4'] },
		],
	},
	{
		//Digicode
		type: 'radio',
		name: 'digicode',
		validation: {
			validate: { requiredField },
		},
		className: 'w-full sm:w-1/2 ',
		title: 'Digicode :',
		tagName: 'equipment.digicode',
		choices: [
			{
				title: 'Non précisé',
				value: '1',
			},
			{
				title: 'Non',
				value: '2',
			},
			{
				title: 'Oui',
				value: '3',
			},
		],
		visible: [
			{ condition: 'EQUAL', field: 'type_offert_id', value: ['1', '2'] },
			{
				condition: 'EQUAL',
				field: 'type_offer_suite_id',
				value: ['1', '2', '3'],
			},
			{
				field: CRITERE.formName,
				value: [...CRITERE_OFFRE.PARKING_CAVE_GARAGE],
				condition: 'INEQUAL',
			},
			{ condition: 'EQUAL', field: 'type_estate_id', value: ['1', '2', '4'] },
		],
	},
];
