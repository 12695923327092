import { requiredField } from '../../../validation/validation';

export const Sector = [
	{
		//Pays de l'annonce
		group: 'info_personnelle',
		type: 'select',
		name: 'advertised_country',
		tagName: 'sector.advertised_country',
		className: 'w-full sm:w-1/2',
		title: "Pays de l'annonce  :",
		validation: {
			validate: {
				requiredField,
			},
		},
		choices: [
			{
				title: 'France',
				value: 'France',
			},
		],
		visible: [
			{ field: 'type_offert_id', value: ['1', '2'] },
			{ field: 'type_offer_suite_id', value: ['1', '2', '3'] },
			{ field: 'type_estate_id', value: ['10'], condition: 'INEQUAL' },
		],
	},
	//   {
	//     //Code postal public
	//     group: "info_personnelle",
	//     type: "number",
	//     name: "public_zap",
	//     className: "w-full sm:w-1/2",
	//     title: "Code postal public :",
	// 	visible: [
	// 		{ field: 'type_offert_id', value: ['1', '2'] },
	// 		{ field: 'type_offer_suite_id', value: ['1', '2', '3'] },
	// 		{ field: 'type_estate_id', value: ['10'], condition: 'INEQUAL' },
	// 	],
	//   },

	{
		//Ville public
		group: 'info_personnelle',
		type: 'seletCity',
		className: 'w-full sm:w-1/2',
		title: 'Ville public :',
		name: 'public_country',
		tagName: 'sector.public_country',
		validation: {
			validate: {
				requiredField,
			},
		},
		visible: [
			{ field: 'type_offert_id', value: ['1', '2'] },
			{ field: 'type_offer_suite_id', value: ['1', '2', '3'] },
			{ field: 'type_estate_id', value: ['10'], condition: 'INEQUAL' },
		],
		target: [
			{
				zip: 'public_zap',
				title: 'Code public',
				tagName: 'sector.public_zap',
			},
		],
	},
	{
		//Ville privée
		group: 'info_personnelle',
		type: 'seletCity',
		className: 'w-full sm:w-1/2',
		title: 'Ville privée :',
		name: 'private_country',
		tagName: 'sector.private_country',
		visible: [
			{ field: 'type_offert_id', value: ['1', '2'] },
			{ field: 'type_offer_suite_id', value: ['1', '2', '3'] },
			{ field: 'type_estate_id', value: ['10'], condition: 'INEQUAL' },
		],
		target: [
			{
				zip: 'private_zap',
				title: 'Code privée',
				tagName: 'sector.private_zap',
			},
		],
	},
	{
		//Addresse Bien
		group: 'info_personnelle',
		type: 'selectInput',
		className: 'w-full sm:w-1/2',
		title: 'Addresse Bien :',
		name: 'property_address',
		tagName: 'sector.property_address',
		withMap: true,
		validation: {
			validate: {
				requiredField,
			},
		},
		visible: [
			{ field: 'type_offert_id', value: ['1', '2'] },
			{ field: 'type_offer_suite_id', value: ['1', '2', '3'] },
			{ field: 'type_estate_id', value: ['10'], condition: 'INEQUAL' },
		],
	},
	{
		//Complement d'adresse
		group: 'info_personnelle',
		type: 'text',
		name: 'address_completed',
		tagName: 'sector.address_completed',
		className: 'w-full sm:w-1/2',
		title: "Complement d'adresse  :",
		visible: [
			{ field: 'type_offert_id', value: ['1', '2'] },
			{ field: 'type_offer_suite_id', value: ['1', '2', '3'] },
			{ field: 'type_estate_id', value: ['10'], condition: 'INEQUAL' },
		],
	},
	{
		//Immeuble/Bâtiment
		group: 'info_personnelle',
		type: 'text',
		className: 'w-full sm:w-1/2',
		title: 'Immeuble/Bâtiment :',
		name: 'building',
		tagName: 'sector.building',
		visible: [
			{ field: 'type_offert_id', value: ['1', '2'] },
			{ field: 'type_offer_suite_id', value: ['1', '2', '3'] },
			{ field: 'type_estate_id', value: ['10'], condition: 'INEQUAL' },
		],
	},
	{
		//Proximité des services
		group: 'info_personnelle',
		type: 'text',
		className: 'w-full sm:w-1/2',
		title: 'Proximités des services :',
		name: 'prox_service',
		tagName: 'sector.prox_service',
		visible: [
			{ field: 'type_offert_id', value: ['1', '2'] },
			{ field: 'type_offer_suite_id', value: ['1', '2', '3'] },
			{ field: 'type_estate_id', value: ['10'], condition: 'INEQUAL' },
		],
	},
	{
		//Environnement
		group: 'info_personnelle',
		type: 'text',
		className: 'w-full sm:w-1/2',
		title: 'Environnement :',
		name: 'environment',
		tagName: 'sector.environment',
		visible: [
			{ field: 'type_offert_id', value: ['1', '2'] },
			{ field: 'type_offer_suite_id', value: ['1', '2', '3'] },
			{ field: 'type_estate_id', value: ['10'], condition: 'INEQUAL' },
		],
	},
];
