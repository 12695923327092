import { useEffect, useState } from 'react';
import { RadioInput } from './RadioInput';
import { SelectInput } from './SelectInput';
import { useWatch } from 'react-hook-form';
import { FileInput } from './FileInput';
import { AdresseInput } from './AdresseInput';
import { SelectInputCountry } from '../../elements/SelectInputCountry';
import { MapInput } from './MapInput';
import { Button } from '../../../button';
import { PricingInput } from './PricingInput';
import { ButtonSpinner } from '../../../buttonSpinner';
import { LocalityCity } from './CityLocality';
import { getStorageUser } from '../../../../../service/localStorage';
import { SelectCity } from '../../elements/selectCity';
import { RadioInputUpdate } from './RadioInputUpdate';
import { FileInputUpdate } from './FileInputUpdate';
import { SelectInputUpdate } from './SelectInputUpdate';
import { LocalityCityUpdate } from './CityLocalityUpdate';
import { AdresseInputUpdate } from './AdresseInputUpdate';
import { PricingInputUpdate } from './PricingInputUpdate';
import { SelectCityUpdate } from '../../elements/selectCityUpdate';
import { isValidJSON } from '../../../../../helpers/attributes';

const simpleInputType = [
	'text',
	'password',
	'date',
	'number',
	'email',
	'tel',
	'url',
	'search',
];
export const ElementFormUpdate = ({
	element,
	style,
	register,
	unregister,
	control,
	errors,
	formState,
	watch,
	dataUpdate,
	setValue,
	getValues,
}) => {
	const [shouldVisibleNow, setShouldVisibleNow] = useState(true);
	const [initialRender, setInitialRender] = useState(0);

	const shouldVisible = () => {
		const isAdmin = getStorageUser()?.user.role === '777';
		if (element.is_admin_only && !isAdmin) {
			setShouldVisibleNow(false);
			return;
		}
		let shouldShow = [];

		if (element.visible) {
			element.visible.forEach((visible) => {
				// Si c'est un radio, alors mettre le checked dans le querySelector
				let doc = document.querySelector(`[name=${visible.field}]`);

				if (doc) {
					if (doc?.type === 'radio')
						doc = document.querySelector(`[name=${visible.field}]:checked`);

					if (doc) {
						if (visible.condition === 'INEQUAL') {
							// LA CONDITION DE VISIBILITÉ EST FAIT DE SORTE QUE LA VALEUR DU FIELD SOIT EGALE À LA VALEUR
							// TRAITER LES VALEURS AVEC UNE CONDITION AND

							shouldShow.push(!visible.value.includes(doc?.value));
						} else {
							if (doc?.type === 'file') shouldShow.push(doc?.files.length > 0);
							else shouldShow.push(visible.value.includes(doc?.value));
						}
					} else {
						shouldShow.push(false);
					}
				}
			});
			setShouldVisibleNow(
				shouldShow.length === 0
					? false
					: shouldShow.every((val) => val === true)
			);
		}
	};

	const watchAllFields = control();

	useEffect(() => {
		if (initialRender === 0) setInitialRender(1);
		if (initialRender === 1) setInitialRender(2);
		shouldVisible();
	}, [initialRender, watchAllFields]);

	useEffect(() => {
		const doc = document.querySelector(`[name="${element.name}"]`);
		let nameToSearch = element.name;

		if (element.tagName) {
			let value = dataUpdate;

			element.tagName.split('.').forEach((item) => {
				if (isValidJSON(value[item])) {
					let data = JSON.parse(value[item]);
					if (Array.isArray(data)) value = data[0];
					else value = data;
				} else {
					value = Array.isArray(value[item]) ? value[item][0] : value[item];
				}
			});

			dataUpdate[element.name] = value;
		}

		const valueToSet = dataUpdate[nameToSearch];

		if (doc) {
			if (element.type === 'file') {
				return;
			}
			doc.value = valueToSet;
		}

		setValue(nameToSearch, valueToSet, {
			shouldTouch: true,
			shouldDirty: true,
		});
	}, [
		initialRender,
		element.name,
		element.tagName,
		element.type,
		dataUpdate,
		shouldVisibleNow,
	]);

	return (
		initialRender === 2 &&
		shouldVisibleNow === true && (
			<div className={style.container}>
				{element.title && (
					<label htmlFor={element.name} className='text-gray-500 font-bold'>
						{element.title}{' '}
						{element.validation?.validate?.requiredField && <>(*)</>}
					</label>
				)}

				{element.type === 'radio' && (
					<RadioInputUpdate
						style={style}
						element={element}
						errors={errors}
						register={register}
						control={watchAllFields}
						dataUpdate={dataUpdate}
						setValue={setValue}
					/>
				)}

				{element.type === 'select' && (
					<SelectInputUpdate
						setValue={setValue}
						getValue={getValues}
						style={style}
						errors={errors}
						element={element}
						watch={watch}
						register={register}
						control={watchAllFields}
						dataUpdate={dataUpdate}
					/>
				)}

				{element.type === 'selectInput' && (
					<AdresseInputUpdate
						style={style}
						element={element}
						errors={errors}
						register={register}
						control={control}
						dataUpdate={dataUpdate}
					/>
				)}

				{element.type === 'locality_city' && (
					<LocalityCityUpdate
						style={style}
						element={element}
						errors={errors}
						register={register}
						unregister={unregister}
						control={control}
						dataUpdate={dataUpdate}
					/>
				)}
				{element.type === 'seletCity' && (
					<SelectCityUpdate
						style={style}
						element={element}
						errors={errors}
						register={register}
						control={control}
						dataUpdate={dataUpdate}
					/>
				)}

				{element.type === 'selectInputCountry' && (
					<SelectInputCountry
						element={element}
						register={register}
						control={control}
						style={style}
						dataUpdate={dataUpdate}
					/>
				)}

				{element.type === 'map' && (
					<MapInput
						style={style}
						element={element}
						errors={errors}
						register={register}
						control={watchAllFields}
					/>
				)}

				{element.type === 'button' && (
					<button
						type='button'
						className='w-[20%] text-white bg-blue-500 hover:bg-blue-700 rounded-md p-3 flex flex-wrap items-start justify-center'
						onClick={() => element.click(watchAllFields)}>
						{element.children}
						<ButtonSpinner showLoading={element.loading} />
					</button>
				)}
				{element.type === 'pricing' && (
					<PricingInputUpdate
						formState={watchAllFields}
						style={style}
						element={element}
						errors={errors}
						register={register}
						control={control}
						setValue={setValue}
						dataUpdate={dataUpdate}
					/>
				)}
				{element.type === 'file' && (
					<FileInputUpdate
						style={style}
						element={element}
						errors={errors}
						register={register}
						control={control}
						dataUpdate={dataUpdate}
					/>
				)}
				{element.type === 'textarea' && (
					<textarea
						defaultValue={dataUpdate[element.name]}
						className={style.text__area}
						{...register(element.name, { ...element.validation })}></textarea>
				)}

				{simpleInputType.includes(element.type) && (
					<>
						<input
							id={element.name}
							type={element.type}
							className={style.simple__input}
							defaultValue={dataUpdate[element.name]}
							{...register(element.name, { ...element.validation })}
						/>
					</>
				)}

				{errors[element.name]?.message && (
					<div className='text-red-600 w-full'>
						{errors[element.name]?.message}
					</div>
				)}
			</div>
		)
	);
};
