export const TYPE_OFFRE = {
	formName: 'type_offert_id',
	VENTE: '1',
	LOCATION: '2',
};
export const TYPE_BIEN = {
	formName: 'type_estate_id',
	MAISON: '1',
	APPARTEMENT: '2',
	TERRAIN: '3',
	AUTRES: '4',
	CESSION: '5',
	FOND_COMMERCE: '6',
};
export const CRITERE = {
	HOME: 'home',
	VILLA: 'villa',
	MAS: 'mas',
	BASTIDE: 'bastide',
	CHATEAU: 'chateau',
	PROPERTY: 'property',
	REZ_VILLA: 'rez_villa',
	FERME: 'farm',
	LONGERE: 'longere',
	APPARTEMENT: 'appartment',
	STUDIO: 'studio',
	DUPLEX: 'duplex',
	TRIPLEX: 'triplex',
	REZ_JARDIN: 'rez_de_jardin',
	LOFT: 'loft',
	TERRAIN: 'terrain',
	TERRAIN_AGRICOLE: 'terrain_agricole',
	TERRAIN_LOISIR: 'terrain_loisir',
	TERRAIN_BATIR: 'terrain_batir',
	TERRAIN_LOTISSEMENT: 'terrain_lotissement',
	TERRAIN_DIFFUS: 'terrain_diffus',
	TERRAIN_VIABILISE: 'terrain_viabilise',
	TERRAIN_NATUREL_FORESTIER: 'terrain_nat_forestier',
	AUTRES: 'other',
	CABANON: 'cabanon',
	CHALET: 'chalet',
	CAVE: 'cave',
	GARAGE: 'garage',
	PARKING: 'parking',
	IMMEUBLE: 'immeuble',
	VIAGER: 'viager',
	formName: 'criteria',
};
export const TYPE_OFFRE_SUITE = {
	CLASSIQUE: '1',
	PROGRAMME_NEUF: '2',
	formName: 'type_offer_suite_id',
	SAISONNIERE: '3',
};

export const CRITERE_OFFRE = {
	formName: 'criteria',
	MAISON: [
		CRITERE.HOME,
		CRITERE.VILLA,
		CRITERE.PROPERTY,
		CRITERE.FERME,
		CRITERE.BASTIDE,
		CRITERE.MAS,
		CRITERE.REZ_VILLA,
		CRITERE.CHATEAU,
	],
	APPARTEMENT: [
		CRITERE.APPARTEMENT,
		CRITERE.REZ_JARDIN,
		CRITERE.DUPLEX,
		CRITERE.TRIPLEX,
		CRITERE.LOFT,
		CRITERE.STUDIO,
	],
	TERRAIN: [
		CRITERE.TERRAIN_AGRICOLE,
		CRITERE.TERRAIN,
		CRITERE.TERRAIN_LOISIR,
		CRITERE.TERRAIN_BATIR,
		CRITERE.TERRAIN_NATUREL_FORESTIER,
		CRITERE.TERRAIN_DIFFUS,
		CRITERE.TERRAIN_VIABILISE,
		CRITERE.TERRAIN_LOTISSEMENT,
	],
	AUTRES: [
		CRITERE.CABANON,
		CRITERE.AUTRES,
		CRITERE.CHALET,
		CRITERE.CAVE,
		CRITERE.GARAGE,
		CRITERE.PARKING,
		CRITERE.IMMEUBLE,
		CRITERE.VIAGER,
	],
	PARKING_CAVE_GARAGE: [CRITERE.GARAGE, CRITERE.PARKING, CRITERE.CAVE],
};
